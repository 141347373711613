export default [
    {
        path: "/home",
        name: "home",
        component: () => import("../views/homepage"),
    },

    {
        path: "/:catchAll(.*)",
        redirect: "/home"
    }
];